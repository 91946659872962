import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';
import {DemoServiceLocal} from './DemoServiceLocal';
import { storelocalstorageAction} from '../store/actions/GetLocalStoreAction';

export function registerNewStudent(fname, lname,mobile, email, std, board,academic,dispatch) {
    let getData = localStorage.getItem('userDetails');
    const postData = {
        email: email,
        fname: fname,
        lname: lname,
        mobile: mobile,
        profile_pic: '',
        device_token:'',
        standard: std,
        board: board,
        academic_year:academic
    };
    //dispatch(storelocalstorageAction(Apis.POST_REGISTER_NEW_STUDENT_API));
    DemoServiceLocal(Apis.POST_REGISTER_NEW_STUDENT_API,getData,postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REGISTER_NEW_STUDENT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

/* API call for verification OTP to user  */
export function otpSendForRegistration(mobile, email, history) {
    let getData = localStorage.getItem('userDetails');
    const postData = {
        mobile,
        email,
    };
    DemoServiceLocal(Apis.POST_SEND_VERIFICATION_OTP_TO_MOBILE_AND_EMAIL_API,getData,postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_SEND_VERIFICATION_OTP_TO_MOBILE_AND_EMAIL_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function checkStudentRecordExistInTheSystemOrNot(mobile, email, history) {
    let getData = localStorage.getItem('userDetails');
    const postData = {
        mobile,
        email,
    };
    DemoServiceLocal(Apis.POST_CHECK_STUDENT_RECORD_EXIST_IN_THE_SYSTEM_OR_NOT_API,getData,postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_CHECK_STUDENT_RECORD_EXIST_IN_THE_SYSTEM_OR_NOT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function askQuestionData(search_text, subject_id) {
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        search_text,
        subject_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_SEARCH_TEXT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function registrationStatus(mobile, email, student_name, mobile_otp_status, email_otp_status, otp_timeout, standard, board, academic_year) {
    let getData = localStorage.getItem('userDetails');
    const postData = {
        mobile,
        student_name,
        email,
        mobile_otp_status,
        email_otp_status,
        otp_timeout,
        standard, 
        board,
        academic_year,
    };
    DemoServiceLocal(Apis.POST_STORE_OTP_VERIFCATION_STATUS_API, getData, postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_STORE_OTP_VERIFCATION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}



